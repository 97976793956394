
function Footer() {
  return (
    <div className="p-8">
      <h1 className="text-center text-white">Everyday © 2023</h1>
      <h1 className="text-center text-white"> v 2.0.0</h1>
    </div>
  );
}

export default Footer;
